import React from 'react';
import "./css/teacherpage.css";
import FAQAdminComponent from '../components/FAQAdminComponent';

export default class AboutPage extends React.Component {

    render() {
        return (
            <div className="main-page-general-styling" style={{maxHeight:'100%'}}>
                <div className="main-page-header glassMorphism">
                    <h2> About </h2>
                </div>
                <div className="main-page-inner-container glassMorphism">
                    <div className="main-page-content tp-page-content">
                    <section className="about-section" style={{height:'100%'}}>
                            <p>
                                Att läsa kan vara krävande, jobbigt, tråkigt… och alldeles, alldeles underbart!
                                Ja, om man hittar rätt bok och läser för sin egen skull så kan böcker ta med dig
                                på alla möjliga äventyr och resor. En välskriven bok kan måla upp fantasivärldar
                                du aldrig kunnat tänka dig, den kan få dig att skratta högt, den kan få dig att gråta
                                eller må riktigt illa, den kan skrämma dig, den kan trösta dig och den kan bli din
                                nya bästa vän. Dessutom lär du dig empati, språk och meningsbyggnad - vilken härlig bonus!
                            </p>

                            <h3 className="about-subheader">Läskuppen och Glädjen i Läsning</h3>
                            <p>
                                Tanken med tävlingen “Läskuppen” är att hitta läsglädjen hos dig själv.
                                Du ska inte läsa för att någon säger till dig, du ska läsa för att du vill!
                            </p>
                            <p>
                                Läskuppen startades år 2007 då svenskläraren Linda kände att eleverna behövde
                                uppmuntras att läsa mer. Under åren har den körts på många olika sätt men nu
                                har den en helt egen plattform.
                            </p>

                            <h3 className="about-subheader">Hur Läskuppen Fungerar</h3>
                            <p>
                                Medan Läskuppen är igång läser eleverna så mycket de vill och alla är med och
                                tävlar både för sig själva och för sin klass, vare sig de läser 1 eller 100 böcker.
                                Priser delas ut till de topp tre individerna som läst mest samt till mest läsande
                                klass (endast de som deltagit dock).
                            </p>
                            <p>
                                Som lärare kan man planera in lite fler läsmoment under lektionerna under den
                                period då Läskuppen körs, det är ett bra sätt att få hela klasser involverade.
                            </p>

                            <h3 className="about-subheader">Kontakta Oss</h3>
                            <p>
                                Om du har frågor kring Läskuppen, kontakta <a href="mailto:linda@laskuppen.se">linda@laskuppen.se</a>.
                            </p>
                            <p>
                                Om du är intresserad och vill ha en demo, kontakta <a href="mailto:joakim@laskuppen.se">joakim@laskuppen.se</a>.
                            </p>
                        </section>

                    </div>
                </div>
            </div>
        )
    }
}
