import React, { useState, useRef, useEffect } from "react";

import "./css/editreviewwindow.css"
import "./css/editbookwindow.css"

function EditBookWindow({onClose, book}) {
  const thumbnailRef = useRef(null);
  const [bookData, setBookData] = useState({
    bookid: book ? book.id : "",
    title: book ? book.title: "",
    author: book ? book.author.replace(/[\[\]"]/g, ""): "",
    descr: book ? book.descr : "",
    pages: book ? book.pages : "",
    thumbnail: book ? book.thumbnail : "",
  });

  const handleUpdateRequest = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bookData),
    };
  
    fetch(`/api/updatebook`, requestOptions)
      .then((response) => {
        if (response.ok) {
          console.log("Succeeded in update")
          onClose();
        } else {
          console.error('Failed to update book');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleThumbnailChange = () => {
    handleReviewChange({
      target: {
        name: 'thumbnail',
        value: thumbnailRef.current.value
      }
    });
    thumbnailRef.current.value = ""
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setBookData({
      ...bookData,
      [name]: value
    });
  };

  console.log("BOOK: ", JSON.stringify(book, null, 2))

  return (
    <div className="rlc-edit-container bpd-edit-container">
      <div className="rlc-edit-data-container bpd-edit-data-container">
        <div className="edit-book-section edit-fields">
          <div className="edit-input">
            <p> Titel </p>
            <input
              name="title"
              type="text"
              value={bookData.title}
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input">
            <p> Författare </p>
            <input
              name="author"
              type="text"
              value={bookData.author.replace(/[\[\]"]/g, "")}
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input filler">
            <p> Beskrivning </p>
            <textarea
              name="descr"
              type="text"
              value={bookData.descr}
              placeholder="Skriv en beskrivning av boken..."
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input">
            <p> Sidor </p>
            <input
              name="pages"
              type="number"
              value={bookData.pages}
              min={0}
              onChange={handleReviewChange}
            />
          </div>
        </div>

        <div className="edit-book-section edit-image">
          <img src={bookData.thumbnail} alt="Saknar bild..." />
          <div className="edit-input image-input-field">
            <input
              ref={thumbnailRef}
              name="thumbnail"
              type="text"
              placeholder="Google book image URL..."
            ></input>
            <button onClick={handleThumbnailChange}>Ladda</button>
          </div>
        </div>

        <div className="bpd-edit-book-button-section">
          <button onClick={handleUpdateRequest}>Skicka</button>
          <button onClick={onClose}>Avbryt</button>
        </div>
      </div>
    </div>
  );
}

export default EditBookWindow;