import "./css/makereview.css";
import Reward from "react-rewards";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import ImageUrlInput from "./ImageUrlInput";

const useFormHook = (formValues) => {
  const [values, handleChange] = useState(formValues);

  return [
    values,
    (e) => {
      handleChange({
        ...values,
        [e.target.name]: e.target.value,
      });
    },
  ];
};

const MakeReviewComponent = ({ create, book}) => {
  var reward;
  const press = () => {
    for (let attr in values) {
      if (values[attr] == null) {
        // WRONG INPUT
        console.log("THISIS VALUES", values)
        reward.punishMe();
        setError("Fyll i alla fält");
        return false;
      }
      if (attr == "review") {
        if ((values[attr].length < 200)) {
          setError(
            "Minst 200 tecken (Du har " + values[attr].length + " tecken)"
          );
          console.log(error);
          reward.punishMe();
          return false;
        }
      }
    }

    reward.rewardMe();
    submitForm();
  };

  const [submitted, handleSubmit] = useState(false);
  const [error, setError] = useState(null);

  const [values, handleChange] = useFormHook({
    title: create ? "" : book.title,
    grade: null,
    recommended: null,
    author: create ? "" : book.author,
    pages: create ? "" : book.pages,
    thumbnail: "",
    desc: "",
    review: null,
  });

  function submitForm(e) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };

    fetch("/api/submitreview", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          handleSubmit(true);
        }, 1000);
      });
  }

  return (
    <div className="mrc-page-content">
      {submitted ? <Redirect to="/toplist" /> : null}
        <div className="mrc-B glassMorphism">
            <div className="mrc-score">
                <p> Betyg </p>
                <input
                    name="grade"
                    type="number"
                    value={values.grade}
                    onChange={(e) => {
                    var val = e.target.value;
                    if (val > 10 || val < 1) return;
                    handleChange(e);
                    }}
                />
            </div>
            <div class="mrc-likeable p-3 border rounded">
                <p class="fw-bold">Läsvärd</p>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="recommended" value="true" id="radioYes" onChange={handleChange}/>
                    <label class="form-check-label" for="radioYes">
                    Ja
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="recommended" value="false" id="radioNo" onChange={handleChange}/>
                    <label class="form-check-label" for="radioNo">
                    Nej
                    </label>
                </div>
            </div>
        </div>
      <div className="mrc-C glassMorphism">
        <div className="mrc-title">
          <p> Titel </p>
          <input name="title" type="text" value={values.title} disabled={!create} onChange={handleChange} />
        </div>
        <div className="mrc-author">
          <p> Författare </p>
          <input name="author" type="text" value={values.author} disabled={!create} onChange={handleChange} />
        </div>
        <div className="mrc-pages">
          <p> Sidor </p>
          <input
            name="pages"
            type="number"
            value={values.pages}
            min={0}
            onChange={handleChange}
            disabled={!create}
          />
        </div>
      </div>
      <div className="mrc-D glassMorphism">
        <p> Recension </p>
        <textarea
          name="review"
          type="text"
          value={values.review}
          placeholder="Skriv här!"
          onChange={handleChange}
        />
        <Reward
          ref={(ref) => {
            reward = ref;
          }}
          type="confetti"
        >
          <button className="btn btn-success" onClick={press}>
            Skicka
          </button>
        </Reward>
        <span style={{ color: "red" }}>{error}</span>
      </div>
    </div>
  );
};

export default MakeReviewComponent;
