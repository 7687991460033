import React, { useState, useRef, useEffect } from "react";
import Chevron from "./Chevron";
import { checkAcc } from "./utils/review";
import { useMediaQuery } from "react-responsive";

import "./css/accordion.css";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [isChecked, setIsChecked] = useState(false)

  const content = useRef(null);

  const small = useMediaQuery({ minWidth: 801 });

  // Toggles the accordion box
  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
    setIsChecked(!isChecked);
  }

  return (
    <div className="accordion__section">
      <div className="accordion-checkbox">
        <input
          className="rlc-checkb"
          name="foo"
          checked={isChecked}
          type="checkbox"
          onChange={() => {
            props.checkRequest(!isChecked, props.book) 
            toggleAccordion();
          }}
        />
      </div>
      <div className="accordion-container">
        <button className={`accordion ${setActive}`} 
                onClick={() => {
                  props.checkRequest(!isChecked, props.book) 
                  toggleAccordion();
                }}>
          <div className="accordion__title">
          <div className="rlc-book">
            <div className="rlc-book-title">Titel: {props.book.title}</div>
            <div className="rlc-book-data">
              {small ? (
                <div className="rlc-book-author">
                  Författare: {props.book.author.replace(/[\[\]"]/g, "")}
                </div>
              ) : (
                ""
              )}
              <div className="rlc-book-pages">Sidor: {props.book.pages}</div>
              <div className="rlc-book-bookid">
                Book ID: {props.book.bookid}
              </div>
            </div>
            <div className="rlc-student-data">
              <div className="rlc-book-writtenby">
                Recensent: {props.book.name}
              </div>
              <div className="rlc-book-reviewid">
                Recension ID: {props.book.rid}
              </div>
            </div>
            <div className="rlc-status-container">
              <div className={"status-" + props.book.status}>
                {checkAcc(props.book.status)}
              </div>
            </div>
            <div className="rlc-edit-button">
              <div className="" onClick={(e) => props.editRequest(e, props.book)}>Redigera</div>
            </div>
          </div>
          </div>
          <Chevron className={`${setRotate}`} width={20}/>
        </button>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion__content"
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{ __html: props.book.summary }}
          />
        </div>
      </div>
    </div>
  );
}

export default Accordion;
