import React, { useState, useRef, useEffect } from "react";

import "./css/editreviewwindow.css"

function EditReviewWindow({onClose, review}) {
  const thumbnailRef = useRef(null);
  const [reviewData, setReviewData] = useState({
    rid: review ? review.rid : "",
    bookid: review ? review.bookid : "",
    title: review ? review.title: "",
    author: review ? review.author.replace(/[\[\]"]/g, ""): "",
    descr: "",
    pages: review ? review.pages : "",
    summary: review ? review.summary : "",
    thumbnail: "",
  });

  useEffect(() => {
    fetchBook(review.bookid);
  }, [])

  const fetchBook = async (id) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    await fetch("/api/getbook/" + id, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const bookData = response[0]
        setReviewData((prevEditData) => ({
          ...prevEditData,
          descr: bookData.descr,
          thumbnail: bookData.thumbnail,
        }))
      });
  }

  const handleUpdateRequest = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reviewData),
    };
  
    fetch(`/api/updatereview`, requestOptions)
      .then((response) => {
        if (response.ok) {
          console.log("Succeeded in update")
          onClose();
        } else {
          console.error('Failed to update review');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleThumbnailChange = () => {
    handleReviewChange({
      target: {
        name: 'thumbnail',
        value: thumbnailRef.current.value
      }
    });
    thumbnailRef.current.value = ""
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviewData({
      ...reviewData,
      [name]: value
    });
  };

  return (
    <div className="rlc-edit-container">
      <div className="rlc-edit-data-container">
        <div className="edit-book-section edit-fields">
          <div className="edit-input">
            <p> Titel </p>
            <input
              name="title"
              type="text"
              value={reviewData.title}
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input">
            <p> Författare </p>
            <input
              name="author"
              type="text"
              value={reviewData.author.replace(/[\[\]"]/g, "")}
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input filler">
            <p> Beskrivning </p>
            <textarea
              name="descr"
              type="text"
              value={reviewData.descr}
              placeholder="Skriv en beskrivning av boken..."
              onChange={handleReviewChange}
            />
          </div>
          <div className="edit-input">
            <p> Sidor </p>
            <input
              name="pages"
              type="number"
              value={reviewData.pages}
              min={0}
              onChange={handleReviewChange}
            />
          </div>
        </div>

        <div className="edit-book-section edit-image">
          <img src={reviewData.thumbnail} alt="Saknar bild..." />
          <div className="edit-input image-input-field">
            <input
              ref={thumbnailRef}
              name="thumbnail"
              type="text"
              placeholder="Google book image URL..."
            ></input>
            <button onClick={handleThumbnailChange}>Ladda</button>
          </div>
        </div>

        <div className="edit-book-section edit-review">
          <p> Recension </p>
          <textarea
            name="summary"
            type="text"
            value={reviewData.summary}
            onChange={handleReviewChange}
          />
          <button onClick={() => handleUpdateRequest()}>Skicka</button>
          <button onClick={onClose}>Avbryt</button>
        </div>
      </div>
    </div>
  );
}

export default EditReviewWindow;