import "./css/reviewlist.css";
import React, { useState, useEffect, useRef } from "react";
import Accordion from "./Accordion";
import EditReviewWindow from "./EditReviewWindow";
import Chevron from "./Chevron";
import { checkAcc } from "./utils/review";

const ReviewListComponent = (props) => {
  const [reviews, setReviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateList, setUpdateList] = useState(true);
  const [editAccordion, setEditAccordion] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([1,2,3,4]);
  const pendAccepting = [];
  const statuses = [1, 2, 3, 4];

  useEffect(() => {
    if (updateList) {
      fetchReviews();
      setUpdateList(false);
    }
  }, [updateList]);

  useEffect(() => {
    const toggleVisibility = () => {
      if ( window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [])

  // Fetches all the reviews
  const fetchReviews = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    await fetch("/api/reviews", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setReviews(response);
      });
  };

  // Accepts the book in the pendaccepting list
  const acceptReviews = () => {
    const request = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    pendAccepting.map((item) => {
      fetch("/api/accrev/" + item.rid, request)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
        });
    });
    setReviews([]);
    setUpdateList(true);
    fetchReviews();
  };

  // Rejects the books in the pendaccepting list
  const rejectReviews = () => {
    const request = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    pendAccepting.map((item) =>
      fetch("/api/rejrev/" + item.rid, request)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
        })
    );
    setReviews([]);
    setUpdateList(true);
    fetchReviews();
  };

  // Publishes the books in the pendaccepting list
  const publishReviews = () => {
    const request = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    pendAccepting.map((item) =>
      fetch("/api/pubrev/" + item.rid, request)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
        })
    );
    setReviews([]);
    setUpdateList(true);
    fetchReviews();
  };

  // Unpublishes the books in the pendaccepting list
  const unpublishReviews = () => {
    const request = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    };

    pendAccepting.map((item) =>
      fetch("/api/unpubrev/" + item.rid, request)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
        })
    );
    setReviews([]);
    setUpdateList(true);
    fetchReviews();
  };

  // Adds a book to the list but if its in there, remove it
  const addReview = (isChecked, book) => {
    if (isChecked) {
      pendAccepting.push(book);
    } else {
      const index = pendAccepting.indexOf(book);
      if (index > -1) {
        pendAccepting.splice(index, 1);
      }
    }
  };

  // Give bonus points to the users in the pendaccepting list
  const bonusPoints = (event) => {
    const points = parseInt(document.getElementById("inputBonus").value);
    const alegibleForPoints = [];

    pendAccepting.map((item) => alegibleForPoints.push(item.uid));

    const uniquePoints = Array.from(new Set(alegibleForPoints));

    uniquePoints.map((item) =>
      fetch("/api/bonus?id=" + item + "&points=" + points)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
        })
    );
    setReviews([]);
    fetchReviews();
  };

  // const deleteBook = (e, book) => {
  //   e.stopPropagation();
  //   console.log("BOOK to delete: ", book)
  //   if (window.confirm("Är du säker?")) {
  //     let req = {
  //       method: "DELETE",
  //       headers: { "Content-Type": "application/json" },
  //     };
  //     fetch("/api/deletereview/" + book.rid, req)
  //       .then((response) => response.text())
  //       .then((response) => {
  //         console.log(response);
  //       });
  //     fetchReviews();
  //   }
  // };

  const handleStatusChange = (value) => {
      setSelectedStatus((prevSelected) => {
        if (prevSelected.includes(value)) {
          return prevSelected.filter((item) => item !== value);
        } else {
          return [...prevSelected, value];
        }
      });
    };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleEditRequest = (e, accordionData) => {
    e.stopPropagation();
    setEditAccordion({...accordionData});
  }

  const closeEditReviewWindow = () => {
    setEditAccordion(null);
    setReviews([]);
    setUpdateList(true);
    fetchReviews();
  };

  const filteredBooks = reviews
    .filter((book) => {
      return selectedStatus.includes(book.status);
    })
    .filter((book) => {
      const titleMatch = book.title.toLowerCase().includes(searchQuery);
      const nameMatch = book.name.toLowerCase().includes(searchQuery);
      return titleMatch | nameMatch;
    });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  // Creates a box to display the reviews and their information
  const showResult = () => {
    return (
      <div className="accordion-section">
        {filteredBooks.map((book) => {
          return (
            <Accordion
              key={book.rid}
              book={book}
              checkRequest={(e) => addReview(e, book)}
              editRequest={(e) => handleEditRequest(e, book)}
            />
          );
        })}
      </div>
    );
  };

  // Click the checkboxes when you markall
  function toggle() {
    const checkboxes = document.getElementsByName("foo");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].click();
    }
    console.log(checkboxes);
  }

  return (
    <div className="rlc-page-content">
      

      {editAccordion && (
          <EditReviewWindow onClose={closeEditReviewWindow} review={editAccordion} />
      )}

      { isVisible && (<div onClick={scrollToTop} className="rlc-jump-button">
        <Chevron width={20}/>
      </div>)}

      <div className="rlc-search-result">
        <h3 style={{ color: "white" }}>Recensioner:</h3>
        <div className="rlc-admin-control-section">
          <button type="button" onClick={() => toggle()}>
            Markera alla
          </button>
          <input
            className="rlc-search-bar"
            type="text"
            placeholder="Sök via titel eller recensent ..."
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <div className="rlc-dropdown">
            <button>Valda Statusar</button>
            <div className="rlc-dropdown-content">
              <label className="rlc-checkbox-item"><input className="rlc-checkbox" type="checkbox" value={1} checked={selectedStatus.includes(1)} onChange={() => handleStatusChange(1)} /><span>Ej godkänd</span></label>
              <label className="rlc-checkbox-item"><input className="rlc-checkbox" type="checkbox" value={2} checked={selectedStatus.includes(2)} onChange={() => handleStatusChange(2)}  /><span>Ej behandlad</span></label>
              <label className="rlc-checkbox-item"><input className="rlc-checkbox" type="checkbox" value={3} checked={selectedStatus.includes(3)} onChange={() => handleStatusChange(3)}  /><span>Godkänd</span></label>
              <label className="rlc-checkbox-item"><input className="rlc-checkbox" type="checkbox" value={4} checked={selectedStatus.includes(4)} onChange={() => handleStatusChange(4)}  /><span>Publicerad</span></label>
            </div>
          </div>
        </div>

        <div className="rlc-admin-action-section">
          <div className="action-field">
            <button type="button" onClick={() => acceptReviews()}>
              Acceptera
            </button>
            <button type="button" onClick={() => rejectReviews()}>
              Avslå
            </button>
            <button type="button" onClick={() => publishReviews()}>
              Publicera
            </button>
            <button type="button" onClick={() => unpublishReviews()}>
              Avpublicera
            </button>
          </div>
          <div className="bonus-field">
            <button type="button" onClick={bonusPoints}>
              Ge
            </button>
            <input
              className="rlc-field"
              type="text"
              placeholder="+Poäng"
              id="inputBonus"
            />
          </div>
        </div>
        <div className="rlc-admin-register-section">
          {showResult()}
        </div>
      </div>
    </div>
  );
};

export default ReviewListComponent;
