import './css/bookspagedetails.css';
import React, { useState, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBookWindow from './EditBookWindow';
import Reward from "react-rewards"

const BooksPageDetailsComponent = (props) => {
    const [book, setBook] = useState({});
    const [reviews, setReviews] = useState([]);
    const [editBook, setEditBook] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const rewardRef = useRef(null);

    useEffect(() => {
        fetchBook();
        fetchReviews();
    }, []);

    useEffect(() => {
        fetch("/api/session").then(response => response.json()).then(response => {
            if(response.role > 2) {
                setIsAdmin(true);
            }
        }).catch(error => console.log("Error during session"))
    }, [])

    // Fetch the book given the id
    const fetchBook = async (str) => {
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json' },
     };
     
    await fetch("/api/getbook/"+props.id, requestOptions).then(response => response.json()).then(response => {
        setBook(response[0])
        });
    } 

    // Fetch all the reviews connected to a book
    const fetchReviews = async (str) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
    
        await fetch("/api/reviews/"+props.id, requestOptions).then(response => response.json()).then(response => {
            setReviews(response);
        });
    }

    // All the reviews connected to a book displayed
    const reviewsToBook = (reviews) => {
        return (
            <div>
                {reviews.map((review) => {
                    return (
                        <div className="review-container glassMorphism">
                            <div className="name">{review.name}</div>
                            <div className="review">{review.summary}</div>
                        </div>
                    )
                })}
            </div>
        )
    }
        
    // To enable routing back to books page
    const history = useHistory();

    const handleWeeklyPick = async (bookid) => {
        const request = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };

        await fetch('/api/setweeklypick/' + bookid, request)
            .then(response => {
                if(response.ok) {
                    rewardRef.current.rewardMe();
                }
            });
    }

    const handleEditRequest = () => {
        setEditBook(true);
    }

    const closeEditBookWindow = () => {
        setEditBook(false);
        fetchBook();
        fetchReviews();
    }

    return (
        <div className="bpd-page-content">
            { editBook && (
                <EditBookWindow onClose={closeEditBookWindow} book={book}/>
            )}
            <div className="bpd-control">
                <div className='bpd-general-control'>
                    <button type='button' onClick={() => history.push('/books')} className='btn btn-primary button-gradient bdp-button'>
                        <FontAwesomeIcon icon={faTimes} color='white' style={{width: 30, height: 30, margin: 0}}/>
                    </button>
                    <button type="button" onClick={() => history.push('/makereview/' + book.id)} class="btn btn-primary button-gradient">Recensera</button>
                </div>
                {isAdmin && (
                    <div className='bpd-admin-control'>
                        <button type='button' onClick={handleEditRequest} className='btn btn-primary button-gradient'>Redigera</button>
                        <button type='button' onClick={() => handleWeeklyPick(book.id)} className='btn btn-primary button-gradient'>
                            <Reward
                                ref={rewardRef}
                                type="confetti"
                                config={{
                                    lifetime: 200,
                                    angle: 90,
                                    elementCount: 30,
                                }}>
                            </Reward>
                            Sätt som veckans bok
                        </button>
                    </div>
                )}
            </div>
            <div className="information glassMorphism">
                <div className='information-data'>
                    <div className="title">Titel: {book.title}</div>
                    <div className="author">Författare: {book.author?.replace(/[\[\]"]/g, "")}</div>
                    <div className="pages">Sidantal: {book.pages}</div>
                </div>
                <div className="image">
                    <img className="bpd-img" width="200" height="auto" src={book.thumbnail} />
                </div>
                
            </div>
            {book.descr && (
                <div className="description glassMorphism">{book.descr}</div>
            )}
            {reviews.length > 0 && (
                <div className="reviews">
                {reviewsToBook(reviews)}
                </div>
            )}
            
        </div>
    )
}

export default BooksPageDetailsComponent;
