export const checkAcc = (status) => {
  switch (status) {
    case 4:
      return "Publicerad";
    case 3:
      return "Godkänd";
    case 2:
      return "Ej behandlad";
    default:
      return "Ej godkänd";
  }
};
