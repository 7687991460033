import fetch from "node-fetch"
import React, { useEffect, useRef, useState } from "react"
import Reward from "react-rewards"
import { useHistory } from "react-router-dom"
import { faTrophy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./css/loginpage.css"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import { ReactComponent as SVG } from "../images/NTI_L.svg"

const useLoginHook = formValues => {
  const [values, handleChange] = useState(formValues)

  return [
    values,
    e => {
      handleChange({
        ...values,
        [e.target.name]: e.target.value,
      })
    },
  ]
}

const LoginPage = props => {
  var reward = useRef(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [wrongCredentials, setWrongCredentials] = useState(false)
  const [correctCredentials, setCorrectCredentials] = useState(false)
  const [classOptions, setClassOptions] = useState([])

  const [credentials, setCredentials] = useLoginHook({
    mail: "",
    password: "",
  })

  const [registerInfo, setRegisterInfo] = useLoginHook({
    mail: "",
    firstName: "",
    lastName: "",
    className: "",
    classId: "",
    password: "",
    password2: "",
  })

  const history = useHistory()

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    fetch("/api/classes", { signal })
      .then(response => response.json())
      .then(response => {
        setClassOptions(response)
      })
      .catch(error => {
        if (error.name !== "AbortError") {
          console.error("Fetch error during session check", error)
        }
      })

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (props.logout === true) {
      fetch("/api/logout", { signal })
        .then(response => response.json())
        .then(response => {
          history.push("/")
          window.location.reload()
        })
        .catch(error => {
          if (error.name !== "AbortError") {
            console.error("Fetch error during logout:", error)
          }
        })
    } else {
      fetch("/api/session", { signal })
        .then(response => response.json())
        .then(response => {
          if (response.login === true) {
            setLoggedIn(true)
          }
        })
        .catch(error => {
          if (error.name !== "AbortError") {
            console.error("Fetch error during logout:", error)
          }
        })
    }

    return () => {
      controller.abort()
    }
  }, [])

  const login = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    }

    fetch("/api/login", requestOptions)
      .then(response => response.json())
      .then(response => {
        if (response === true) {
          reward.rewardMe()
          setWrongCredentials(false)
          setCorrectCredentials(true)
          setTimeout(() => window.location.reload(), 1000)
        } else {
          reward.punishMe()
          setWrongCredentials(true)
        }
      })
  }

  const register = () => {
    if (!registerInfo.mail.endsWith("ntig.se")) {
      reward.punishMe()
      setWrongCredentials(true)
      return
    }

    const temp = registerInfo["className"]
    registerInfo["className"] = classOptions.find(obj => obj.classname === temp).classname
    registerInfo["classId"] = classOptions.find(obj => obj.classname === temp).id

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(registerInfo),
    }

    fetch("/api/register", requestOptions)
      .then(response => response.json())
      .then(response => {
        if (response === true) {
          reward.rewardMe()
          setWrongCredentials(false)
          setCorrectCredentials(true)
          setTimeout(() => window.location.reload(), 1000)
        } else {
          reward.punishMe()
          setWrongCredentials(true)
        }
      })
  }

  const registerForm = bool => {
    setWrongCredentials(false)
    setShowRegister(bool)
  }

  const loginForm = e => {
    if (e.key === "Enter") {
      login()
    }
  }

  const loginAbout = bool => {
    window.location.href = "/about"
  }

  return (
    <div className='login-page'>
      <span className='logo'>
        <SVG />
      </span>

      {showRegister ? (
        <div className='center-login'>
          <div className='register-box glassMorphism'>
            <p>Registrera</p>
            <div className='register-user'>
              <input value={registerInfo.mail} name='mail' type='text' onChange={setRegisterInfo} onKeyDown={e => loginForm(e)} placeholder='Mail (elev.ga.ntig.se)' />
            </div>

            <div className='register-firstName'>
              <input value={registerInfo.firstName} name='firstName' type='text' onChange={setRegisterInfo} onKeyDown={e => loginForm(e)} placeholder='Förnamn' />
            </div>

            <div className='register-lastName'>
              <input value={registerInfo.lastName} name='lastName' type='text' onChange={setRegisterInfo} onKeyDown={e => loginForm(e)} placeholder='Efternamn' />
            </div>

            <div className='register-className'>
              <select name='className' itemType='text' value={registerInfo.className} onChange={setRegisterInfo} onKeyDown={e => loginForm(e)}>
                <option value='' disabled>
                  Klass...
                </option>
                {classOptions.map(({ id, classname }) => (
                  <option key={id} value={classname}>
                    {classname}
                  </option>
                ))}
              </select>
            </div>

            <div className='register-password'>
              <input name='password' type='password' onChange={setRegisterInfo} onKeyDown={e => loginForm(e)} placeholder='Lösenord' />
            </div>

            <div className='register-password2'>
              <input name='password2' type='password' onChange={setRegisterInfo} onKeyDown={e => loginForm(e)} placeholder='Upprepa lösenord' />
            </div>

            <div className='login-interaction-section'>
              <div className='register-button'>
                <button
                  type='button'
                  onClick={() => {
                    register()
                  }}
                  className='btn btn-primary button-gradient register-button'>
                  Registrera
                </button>
              </div>
              <Reward
                ref={ref => {
                  reward = ref
                }}
                type='memphis'>
                {wrongCredentials ? <div style={{ color: "red" }}>Fel uppgifter</div> : null}
                {correctCredentials ? <div style={{ color: "green" }}>Välkommen!</div> : null}
              </Reward>
              <div className='register-button'>
                <button
                  type='button'
                  onClick={() => {
                    registerForm(false)
                  }}
                  className='btn btn-primary button-gradient register-button'>
                  Logga in istället
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='center-login'>
          <div className='login-box glassMorphism'>
            <div className='login-user'>
              <input value={credentials.mail} name='mail' type='text' onChange={setCredentials} onKeyDown={e => loginForm(e)} placeholder='Användarnamn' />
            </div>

            <div className='login-password'>
              <input name='password' type='password' onChange={setCredentials} onKeyDown={e => loginForm(e)} placeholder='Lösenord' />
            </div>

            <div className='login-interaction-section'>
              <button
                type='button'
                onClick={() => {
                  login()
                }}
                className='btn btn-primary button-gradient register-button'>
                Logga in
              </button>
              <Reward
                ref={ref => {
                  reward = ref
                }}
                type='memphis'>
                {wrongCredentials ? <div style={{ color: "red" }}>Fel uppgifter</div> : null}
                {correctCredentials ? <div style={{ color: "green" }}>Välkommen!</div> : null}
              </Reward>

              <button
                type='button'
                onClick={() => {
                  registerForm(true)
                }}
                className='btn btn-primary button-gradient register-button'>
                Registrera
              </button>

              <div type='button' className='login-about-button' onClick={() => loginAbout()}>
                <FontAwesomeIcon icon={faInfoCircle} className='login-about-svg'></FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LoginPage
