import React from 'react';
import MakeReviewComponent from '../components/MakeReviewComponent';
import ChooseBookComponent from '../components/ChooseBookComponent';

export default class ReviewPage extends React.Component {

    constructor(props) {
        super();
        this.state = { book: null, create: false };
        this.setBook = this.setBook.bind(this);
        this.setCreate = this.setCreate.bind(this);
    }

    
    componentDidMount(){
        if(this.props.match.params.id > 0){
            console.log(this.props.match.params.id)

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
    
            fetch("/api/getbook/".concat(this.props.match.params.id), requestOptions).then(response => response.json()).then(response => {
                this.setBook(response[0]);
            }
            );
            
        }
    }

    setBook(book){
        console.log(book)
        this.setState({book:book});
    }

    setCreate(create) {
        this.setState({ create });
    }

    render() {
        return (
            
            <div className="main-page-general-styling">
            <div className="main-page-header glassMorphism">
                <h2> {this.state.create ? "Lägg till en bok" : (this.state.book == null) ? "Välj bok" : "Skriv Recension"} </h2> 
            </div>
            <div className="main-page-inner-container">
                {(this.state.book == null) && (!this.state.create) ? <ChooseBookComponent setBook={this.setBook} setCreate={this.setCreate}/> : <MakeReviewComponent create={this.state.create} book={this.state.book}/>}
            </div>
        </div>
        )
    }
}
