import "./css/choosebook.css";
import React, { useState, useEffect, useRef } from "react";

import { ReactComponent as SVG } from "../images/add_green.svg";

const ChooseBookComponent = (props) => {
  var search;
  var fetching = false;

  const inputRef = useRef(null);
  const [books, setBooks] = useState([]);
  const [bestBooks, setBestBooks] = useState([]);
  const [recentBooks, setRecentBooks] = useState([]);

  // Component did mount or update we run these
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch("/api/mostreadbook", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setBestBooks(response?.slice(0, 2));
      });

    fetch("/api/latestreviews", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setRecentBooks(response?.slice(0, 2));
      });
  }, []);

  // Fetch books from google
  const fetchFromGoogle = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const target = inputRef.current.value;
    await fetch("/api/books/".concat(target), requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setBooks(response);
      });
  };

  // Search from database and if we can't find any search from google
  const fetchFromDatabase = async (str) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    await fetch("/api/booksearch/".concat(str.toLowerCase()), requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setBooks(response);
      });

    if (str != search) {
      await fetchFromDatabase(search);
    }
  };

  // Fetch to keep it synchronious
  const fetchData = async (str) => {
    await fetchFromDatabase(str);
    fetching = false;
  };

  // Checks if we need to update
  const updateSearch = (event) => {
    search = event.target.value;

    if (search.length == 0) {
      setBooks([]);
    } else if (!fetching) {
      fetching = true;
      fetchData(search);
    }
  };

  // Shows the search results
  const showResult = (books) => {
    return (
      <div className="cbc-book-results">
        {books?.map((book) => {
          return (
            <div
              key={book.id}
              className="cbc-book glassMorphism"
              onClick={() => {
                props.setBook(book);
              }}
            >
              <div className="cbc-book-title">Titel: {book.title}</div>
              <div className="cbc-book-author">
                Författare:{" "}
                {Array.isArray(book.author)
                  ? book.author
                  : JSON.parse('"' + book.author.replace(/[\[\]"]/g, "") + '"')}
              </div>
              <div className="cbc-book-pages">Sidor: {book.pages}</div>
              <div className="cbc-book-img">
                <img
                  src={
                    book.thumbnail
                      ? book.thumbnail.thumbnail
                        ? book.thumbnail.thumbnail
                        : book.thumbnail
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/No_image_available_450_x_600.svg/450px-No_image_available_450_x_600.svg.png"
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="cbc-page-content ">
      <div className="cbc-search-result glassMorphism">
        <div className="cbc-search-bar">
          <input
            ref={inputRef}
            type="text"
            className="inputReview"
            placeholder="Sök bok via boktitel..."
            onChange={updateSearch}
          />
          <button
            onClick={fetchFromGoogle}
            className="cbc-button btn btn-primary button-gradient"
          >
            Utökad Sökning
          </button>
          <button
            onClick={() => {
              props.setCreate(true);
            }}
            className="cbc-button btn btn-primary button-gradient"
          >
            Lägg till Ny Bok
          </button>
        </div>
        {showResult(books, 40)}
      </div>

      <div className="cbc-best-result glassMorphism">
        <div className="">Populära böcker:</div>
        {showResult(bestBooks)}
      </div>

      <div className="cbc-recent-result glassMorphism">
        <div className="">Nyligen recenserade:</div>
        {showResult(recentBooks)}
      </div>
    </div>
  );
};

export default ChooseBookComponent;
