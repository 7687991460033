import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./css/bookslist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const BooklistComponent = (props) => {
  const big = useMediaQuery({ minWidth: 1401 });
  const medium = useMediaQuery({ minWidth: 1101 });
  const small = useMediaQuery({ minWidth: 801 });

  const [currentFilter, setCurrentFilter] = useState("title");
  const [sorting, setSorting] = useState("asc");
  const [search, setSearch] = useState("");
  const [Books, setBooks] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false)

  function setFilter(filter) {
    if (currentFilter == filter) {
      // same filter pressed
      setSorting(sorting == "asc" ? "desc" : "asc");
    } else {
      if (filter == "pages" || filter == "grade") setSorting("desc");
      else setSorting("asc");
      setCurrentFilter(filter);
    }
  }

  useEffect(() => {
    fetch("/api/session").then(response => response.json()).then(response => {
      if(response.role > 2) {
          setIsAdmin(true);
      }
    })
    fetchReviewBooks();
  }, [sorting, currentFilter, search]);

  //Fetches the books from DB with filters and search
  const fetchReviewBooks = () => {
    fetch(
      "/api/reviewedbooks?filter=" +
        currentFilter +
        "&sorting=" +
        sorting +
        "&search=" +
        search
    )
      .then((response) => response.json())
      .then((response) => {
        setBooks(response);
      });
  };

  function isValidJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function parseAuthors(authors) {
    var res = "";

    if (isValidJson(authors)) {
      JSON.parse(authors).forEach((author) => {
        res += author + ", ";
      });
    } else {
      // If it's not a valid JSON, treat it as a normal string
      res = authors;
    }

    return res.slice(0, -2);
  }

  // Enables to route the clicks on a book to a new page
  const history = useHistory();

  return (
    <div className="bl-content-grid glassMorphism">
      <div className="bl-searchbar">
        <span>Sök:</span>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Titel/Författare"
        />
      </div>

      <div className="bl-container-table">
        <table className="table bl-table">
          <thead>
            <tr className="bl-table-header">
              <th onClick={() => setFilter("title")} width="50%" scope="col">{currentFilter == "title" ? <FontAwesomeIcon icon={sorting == "desc" ? faCaretUp : faCaretDown} /> : ""}Titel</th>
              {small ? <th onClick={() => setFilter("author")} width="30%" scope="col">{currentFilter == "author" ? <FontAwesomeIcon icon={sorting == "desc" ? faCaretUp : faCaretDown} /> : ""}Författare</th> : null}
              {big ? <th onClick={() => setFilter("pages")} width="10%" scope="col">{currentFilter == "pages" ? <FontAwesomeIcon icon={sorting == "desc" ? faCaretUp : faCaretDown} /> : ""}Sidor</th> : null}
              {medium ? <th onClick={() => setFilter("grade")} width="10%" scope="col">{currentFilter == "grade" ? <FontAwesomeIcon icon={sorting == "desc" ? faCaretUp : faCaretDown} /> : ""}Snittbetyg</th> : null}
            </tr>
          </thead>
          <tbody>
            {Books
            .filter((listItem) => {
              return listItem.accepted === true
            })            
            .map((listItem) => (
              <tr
                key={listItem.id}
                onClick={() => history.push("/books/" + listItem.id)}
              >
                <td>{listItem.title}</td>
                {small ? <td>{parseAuthors(listItem.author)}</td> : null}
                {big ? <td>{listItem.pages}</td> : null}
                {medium ? <td>{Math.round(listItem.grade)}/10</td> : null}
              </tr>
            ))}
          </tbody>
        </table>

        { isAdmin && (
          <>
          <h4>Böcker som inte accepterats ännu</h4>
          <div className="bl-admin-container-table">
            <table className="table bl-table admin-table">
              <thead>
                <tr className="bl-table-header">
                  <th width="50%" scope="col">Titel</th>
                  {small ? <th width="30%" scope="col">Författare</th> : null}
                  {big ? <th width="20%" scope="col">Sidor</th> : null}
                </tr>
              </thead>
              <tbody>
                {Books
                .filter((listItem) => {
                  return listItem.accepted === false
                })            
                .map((listItem) => (
                  <tr
                    key={listItem.id}
                    onClick={() => history.push("/books/" + listItem.id)}
                  >
                    <td>{listItem.title}</td>
                    {small ? <td>{parseAuthors(listItem.author)}</td> : null}
                    {big ? <td>{listItem.pages}</td> : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BooklistComponent;
